<template>
    <v-main>
        <div class="pt-9 pb-9 pr-9 pl-9">
            <v-container>
                <v-form>
                    <div>
                        <legend class="mb-3">
                            Faça o upload da planilha para solicitação de limites por lote
                        </legend>

                        <FilePicker
                            v-show="uploadCargaARRequestStatus === RequestStatusEnum.IDLE"
                            ref="filePicker"
                            @onReset="resetted"
                            @onFileChange="fileChanged"
                            :acceptedFileTypes="fileTypes"
                        />
                    </div>

                    <v-row
                        align="center"
                        justify="center"
                        v-if="uploadCargaARRequestStatus === RequestStatusEnum.START"
                        class="mt-3"
                    >
                        <v-progress-circular
                            :size="70"
                            :width="6"
                            color="secondary"
                            indeterminate
                        ></v-progress-circular>
                    </v-row>

                    <section
                        class="section-response"
                        v-if="uploadCargaARRequestStatus.type === RequestStatusEnum.ERROR"
                    >
                        <textarea
                            class="custom-textarea mt-6"
                            v-model="uploadCargaARRequestStatus.message"
                        >
                        </textarea>
                    </section>

                    <footer align="right" class="mt-6">
                        <v-btn
                            v-show="uploadCargaARRequestStatus.type === RequestStatusEnum.ERROR"
                            @click="reset"
                            color="secondary"
                            depressed
                            >Refazer</v-btn
                        >

                        <v-btn
                            v-show="uploadCargaARRequestStatus.type !== RequestStatusEnum.ERROR"
                            :disabled="
                                !selectedFile ||
                                uploadCargaARRequestStatus === RequestStatusEnum.START
                            "
                            @click="uploadFile"
                            color="secondary"
                            depressed
                            >Confirmar</v-btn
                        >
                    </footer>
                </v-form>
            </v-container>
        </div>
    </v-main>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import { notification } from '@/helpers/notification';
import { RequestStatusEnum } from '@/helpers/consts';
import FilePicker from '@/components/base/FilePicker/FilePicker';

export default {
    components: {
        FilePicker,
    },
    data() {
        return {
            uploadedFiles: [],
            uploadError: null,
            fileCount: null,
            selectedFile: null,
            RequestStatusEnum,
            fileTypes:
                '.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel',
        };
    },
    methods: {
        ...mapActions('importacoes', {
            uploadCargaARRequest: 'uploadCargaARRequest',
            dismissUploadCargaARRequestStatus: 'dismissUploadCargaARRequestStatus',
        }),
        uploadFile() {
            const formData = new FormData();
            formData.append('file', this.selectedFile, this.selectedFile.name);
            this.uploadCargaARRequest({ idProduct: this.selectedProduct.id, formData });
        },
        reset() {
            this.dismissUploadCargaARRequestStatus();
            this.selectedFile = null;
        },
        resetted() {
            this.selectedFile = null;
        },
        fileChanged(file) {
            this.selectedFile = file;
        },
    },
    computed: {
        ...mapGetters('importacoes', {
            uploadCargaARRequestStatus: 'uploadCargaARRequestStatus',
            selectedProduct: 'selectedProduct',
        }),
    },
    watch: {
        uploadCargaARRequestStatus(newValue) {
            if (newValue === RequestStatusEnum.SUCCESS) {
                notification(this.$dialog.notify.success, 'Carga de AR efetuada com sucesso');
                if (this.$refs.filePicker.reset) this.$refs.filePicker.reset();
                this.reset();
            }
        },
    },
};
</script>
